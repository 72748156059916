import { AppShare } from '@burinka/visual-library';
import {
  type GoogleAnalytics,
  type NodeGoogleAnalytics,
  type SendModelGoogleAnalytics
} from '@/apollo/schema/GoogleAnalytics';

import googleAnalytics from '@/apollo/queries/GoogleAnalyticsAcf.gql';

interface CustomParams {
  [key: string]: unknown;
}

export const useAppAnalytics = () => {
  const { $gtag } = useNuxtApp();
  const route = useRoute();

  function pageView (args: CustomParams): void {
    // @ts-ignore
    $gtag({
      event: 'pageView',
      documentPath: route.path,
      ...args
    });
  }

  function event (eventName: string, args: CustomParams): void {
    // @ts-ignore
    $gtag({
      event: eventName,
      ...args
    });
  }

  function handleClickSendGTM (buttonType: string, args: CustomParams = {}): void {
    event('buttonClick', {
      buttonType,
      ...args
    });
  }

  function setLocalGoogleAnalyticsToStore (metaGoogleAnalytics: SendModelGoogleAnalytics) {
    AppShare.setGoogleAnalytics({
      name: metaGoogleAnalytics.pageName,
      category: metaGoogleAnalytics.pageCategory,
      type: metaGoogleAnalytics.pageType,
      segment: metaGoogleAnalytics.pageSegment
    });
  }

  function setGoogleAnalyticsToStore (metaGoogleAnalytics: GoogleAnalytics) {
    AppShare.setGoogleAnalytics(metaGoogleAnalytics);
  }

  function sendGoogleAnalyticsPageViewFromStore (): void {
    const { googleAnalytics } = AppShare.state;

    pageView({
      pageCategory: googleAnalytics.category,
      pageName: googleAnalytics.name,
      pageSegment: googleAnalytics.segment,
      pageType: googleAnalytics.type
    });
  }

  async function fetchGoogleAnalytics () {
    try {
      const { data } = await useAsyncQuery<NodeGoogleAnalytics>(googleAnalytics, {
        uri: route.path
      });

      if (!data.value || !data.value.contentNode) {
        return;
      }

      const { metaGoogleAnalytics } = data.value.contentNode;

      AppShare.setGoogleAnalytics(metaGoogleAnalytics);

      pageView({
        pageCategory: metaGoogleAnalytics.category,
        pageName: metaGoogleAnalytics.name,
        pageSegment: metaGoogleAnalytics.segment,
        pageType: metaGoogleAnalytics.type
      });
    } catch (e) {
      console.log(e);
    }
  }

  return {
    event,
    pageView,
    handleClickSendGTM,
    fetchGoogleAnalytics,
    setGoogleAnalyticsToStore,
    setLocalGoogleAnalyticsToStore,
    sendGoogleAnalyticsPageViewFromStore
  };
};
