interface Breadcrumb {
  title: string;
  url: string;
}

export const useBreadcrumb = () => {
  const breadcrumbs = useState<Breadcrumb[]>('breadcrumbs', () => []);

  const defaultHome: Breadcrumb = {
    title: '',
    url: '/'
  };

  function initStoreBreadcrumbs () {
    breadcrumbs.value = [defaultHome];
  }

  function setNodeBreadcrumbs (items: Breadcrumb[]) {
    breadcrumbs.value = items;
  }

  function setBreadcrumbs (items: Breadcrumb[] = []) {
    breadcrumbs.value = [
      defaultHome,
      ...items
    ];
  }

  function getBreadcrumbs () {
    return breadcrumbs.value;
  }

  return {
    initStoreBreadcrumbs,
    setNodeBreadcrumbs,
    setBreadcrumbs,
    getBreadcrumbs
  };
};
